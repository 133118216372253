import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Content from 'components/Content';
import SectionTitle from 'components/SectionTitle';
import FixedWidthCol from 'components/FixedWidthCol';
import { Hero } from './product-information-import';
import Toggle, { ToggleVariant } from 'components/Toggle';
import List from 'components/List';
import { device } from 'src/theme/breakpoints';

const StyledContent = styled(Content)`
  margin-top: 80px;

  @media ${device.mobileDOWN} {
    margin-top: 48px;
  }
`;

const OMPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/order-management/om-main.jpg"
            alt={t('om_heading')}
            layout="fullWidth"
          />
        }
        title={t('om_heading')}
        text={t('om_text')}
      />
      <StyledContent>
        <FixedWidthCol>
          <Toggle
            variant={ToggleVariant.BRAVO}
            content={[
              {
                title: t('om_tab_1_title'),
                content: (
                  <>
                    <p>
                      <Trans i18nKey="om_customer_text" />
                    </p>
                    <SectionTitle subtitle={t('om_customer_benefits_subtitle')}>
                      <Trans i18nKey="om_customer_benefits_title" />
                    </SectionTitle>
                    <List
                      items={[
                        t('om_customer_benefits_list_1'),
                        t('om_customer_benefits_list_2'),
                        t('om_customer_benefits_list_3'),
                      ]}
                    />
                    <SectionTitle>
                      <Trans i18nKey="om_customer_how_title" />
                    </SectionTitle>
                    <List
                      items={[
                        t('om_customer_how_list_1'),
                        t('om_customer_how_list_2'),
                        t('om_customer_how_list_3'),
                      ]}
                    />
                  </>
                ),
              },
              {
                title: t('om_tab_2_title'),
                content: (
                  <>
                    <p>
                      <Trans i18nKey="om_supplier_text" />
                    </p>
                    <SectionTitle>
                      <Trans i18nKey="om_customer_how_title" />
                    </SectionTitle>
                    <List
                      items={[
                        t('om_supplier_how_list_1'),
                        t('om_supplier_how_list_2'),
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
        </FixedWidthCol>
      </StyledContent>
    </Layout>
  );
};

export default OMPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Order Management | OmniGet"
    description="Streamline your order processing with OmniGet's efficient order management system. Track, manage, and fulfill orders seamlessly in one place."
  />
);
